import { Component, OnInit } from '@angular/core';
import { Fault } from '../fault';
import { FaultService } from '../fault.service';
import { TrashService } from '../trash.service';
import { ToastrService } from 'ngx-toastr';
import { Subscription, interval } from 'rxjs';

@Component({
  selector: 'app-trash',
  templateUrl: './trash.component.html',
  styleUrls: ['./trash.component.css']
})
export class TrashComponent implements OnInit {
  faults: Fault[] = [];
  page: number = 0;
  pageSize: number = 100;
  state: String = 'list';
  selectedFault: Fault;
  commandSubscription: Subscription;
  updateSubscription: Subscription;

  constructor(private faultService: FaultService, private trashService: TrashService, private toast : ToastrService) {
    this.selectedFault = new Fault();
  }

  ngOnInit() {
    this.getFaults();
  }

  getFaults(): void {
    this.trashService.getTrashFaults()
      .subscribe(faults => this.faults = faults);
  }

  restoreTrash(fault: Fault) : void {
    fault.isActive = true;

    this.faultService.updateFault(fault).subscribe(() => {
      this.getFaults();
      this.toast.success(`Fault ${fault.faultNo} Restored`);
    });
  }
}
