import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { Fault } from './fault';
import { API } from './constants';

@Injectable({
  providedIn: 'root'
})
export class TrashService {

  private trashUrl = API.URL + 'trashs/';//'faults/trash/';

  constructor(private http: HttpClient) { }

  getTrashFaults (): Observable<Fault[]> {
    return this.http.get<Fault[]>(`${this.trashUrl}`).pipe(
      map((result : Fault[]) => {
        // you need to sort, if your data was not sorted
        return result.sort((a : Fault, b: Fault) => {
            if(a.createdAt > b.createdAt) return -1;
            if(a.createdAt < b.createdAt) return 1;
            return 0;
        });
      }),
      tap(_ => this.log(`found faults reviewd`)),
      catchError(this.handleError<Fault[]>('searchFaults', []))
    );
  }

  private handleError<T> (operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
 
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead
 
      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);
 
      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  private log(message: string) {
    // this.messageService.add(`FaultService: ${message}`);
  }
}
