export class Column {
    fieldName : string;
    headerText : string;
    columnWidth : number;

    constructor(fieldName, headerText, columnWidth) {
        this.fieldName = fieldName;
        this.headerText = headerText;
        this.columnWidth = columnWidth;
    }
}