import { Component, OnInit, Input } from '@angular/core';
import { UserService } from '../user.service';
import { User } from '../user';
import { ToastrService } from 'ngx-toastr';
import { CommandService } from '../command.service';
import { Subscription, Observable, Subject, interval } from 'rxjs';

@Component({
  selector: 'app-accounts',
  templateUrl: './accounts.component.html',
  styleUrls: ['./accounts.component.css']
})
export class AccountsComponent implements OnInit {
  message: any;
  users: User[] = [];
  rms: User[] = [];
  @Input() newUser: User = new User();
  disableRemove: boolean = true;
  commandSubscription: Subscription;
  selectedUser: User = null;
  state: String = 'list';

  constructor(private userService: UserService, private toast : ToastrService, private commandService: CommandService) { 
    this.commandSubscription = this.commandService.commands$.subscribe(
      command => {
        switch(command) {
          case 'account-updated':
            this.state = 'list';
            this.getUsers();
            this.getRms();
            break;
        }
      }
    )
  }

  ngOnInit() {
    this.getUsers();
    this.getRms();
    this.setDisable();
  }

  getUsers() {
    this.userService.getUsers()
      .subscribe(users => {
        if (users) {
          this.users = users;
        }
      });
  }

  getRms() {
    this.userService.getRmUsers()
      .subscribe(rms => {
        if (rms) {
          this.rms = rms;
        }
      });
  }

  showUser(user: User) : void {
    this.state = 'detail';
    this.selectedUser = new User(user);
  }

  hideUser() : void {
    this.state = 'list';
    this.selectedUser = null;
  }

  createUser() {
    this.userService.errorMessage = null;
    this.message = null;

    if (!this.newUser.postName) {
      this.message = { text:'Please input post name', type:'error' };
      return;
    }

    if (!this.newUser.role) {
      this.message = { text:'Please select role', type:'error' };
      return;
    }

    if (this.newUser.role === 'SI/I') {
      if(!this.newUser.supervisor) {
        this.message = { text:'Please select supervisor', type:'error' };
        return;
      }
    }

    this.userService.createUser(this.newUser).subscribe(() => {
      if(this.userService.errorMessage && this.userService.errorMessage.length > 0) {
        this.message = { text:this.userService.errorMessage, type:'error' };
        return;
      }

      this.getUsers();
      this.toast.success(`User ${this.newUser.postName} Created`);
    });
  }

  removeUser(user: User) : void {
    this.userService.removeUser(user).subscribe(() => {
      this.getUsers();
      this.toast.success(`User ${user.postName} Removed`);
    });
  }

  setDisable() {
    let userPermissions = JSON.parse(localStorage.getItem('userPermissions'));
    if(userPermissions) {

      userPermissions[0].permissions.forEach(permission => {
          if(permission === 'account.remove') {
            this.disableRemove = false;
          } 
        }
      );
    }
    else {
      this.disableRemove = true;
    }
  }
}
