import { Component, OnInit, Input } from '@angular/core';
import { CommentService } from '../comment.service';
import { Comment } from '../comment';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-comments',
  templateUrl: './comments.component.html',
  styleUrls: ['./comments.component.css']
})
export class CommentsComponent implements OnInit {
  message: any;
  comments: Comment[] = [];
  @Input() newComment: Comment = new Comment();
  page: number = 0;
  pageSize: number = 100;

  constructor(private commentService: CommentService, private toast : ToastrService) { }

  ngOnInit() {
    this.getComments();
  }

  getComments() {
    this.commentService.getComments()
      .subscribe(comments => {
        if (comments) {
          this.comments = comments;
        }
      });
  }

  createComment() {
    this.commentService.errorMessage = null;
    this.message = null;

    let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.newComment.postName = currentUser.postInformation.uid;
    this.newComment.time = new Date();

    this.commentService.createComment(this.newComment).subscribe(() => {
      if(this.commentService.errorMessage && this.commentService.errorMessage.length > 0) {
        this.message = { text:this.commentService.errorMessage, type:'error' };
        return;
      }

      this.getComments();
      this.toast.success(`Comment Created Successfully`);
    });
  }
}
