import { Component, OnInit } from '@angular/core';
import { Fault } from '../fault';
import { WatchlistService } from '../watchlist.service';
import { CommandService } from '../command.service';
import { Subscription, interval } from 'rxjs';
import { ExcelService } from '../excel/excel.service';
import { Column } from '../excel/column';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  faults: Fault[] = [];
  page: number = 0;
  pageSize: number = 100;
  state: String = 'list';
  selectedFault: Fault;
  commandSubscription: Subscription;
  updateSubscription: Subscription;

  constructor(private watchlistService: WatchlistService, private commandService: CommandService, private excelService: ExcelService) {
    this.selectedFault = new Fault();
    
    this.commandSubscription = this.commandService.commands$.subscribe(
      command => {
        switch(command) {
          case 'create-fault':
            this.state = 'detail';
            this.selectedFault = new Fault();
            break;
          case 'fault-created':
          case 'fault-updated':
            this.state = 'list';
            this.getFaults();
            break;
        }
      }
    )
    
    this.updateSubscription = interval(10000).subscribe(
      (val) => { this.getFaults(); }
    )
  }

  ngOnInit() {
    this.getFaults();
  }

  ngOnDestroy() {
    this.updateSubscription.unsubscribe();
  }

  getFaults(): void {
    this.watchlistService.getMajorFaults()
      .subscribe(faults => this.faults = faults);
  }
  
  hideFault() : void {
    this.state = 'list';
    this.selectedFault = null;
  }

  exportExcel() : void {
    let headers: Column[] = [
      new Column('faultNo', 'Fault No.', 12),
      new Column('client', 'Client', 36),
      new Column('location', 'Location', 28),
      new Column('description', 'Description', 61),
      new Column('engineerComment', 'Engineer Comment', 16),
      new Column('engineerStatus', 'Status', 10),
      new Column('createdAt', 'Reported Time', 25),
      new Column('updatedAt', 'Last Update Time', 25),
    ];

    let data = this.cloneAndFormat(this.faults);
    console.log(data);
    this.excelService.exportAsExcelFile(data, 'watchlist', headers);
  }

  cloneAndFormat(faults: Fault[])  {
    return faults.map(function (fault) {
      let clone = {};
      for(let key in fault) {
        switch (key) {
          case 'createdAt':
          case 'updatedAt':
            clone[key] = formatDate(fault[key], 'MMM d, yyyy h:mm aa', 'en-US');
            break;
          default:
            clone[key] = fault[key];
        }
      }
      return clone;
    });
  }

  refresh(): void {
    this.ngOnInit();
  }
}
