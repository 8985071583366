import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { Fault } from './fault';
import { Geocode } from './geocode';
import { API } from './constants';
import { GEOCODE } from './constants';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class FaultService {
  
  private faultsUrl = API.URL + 'faults/';
  // private geocodeUrl = GEOCODE.URL + 'key=' + GEOCODE.KEY + '&location=';
  private geocodeUrl = GEOCODE.URL;

  constructor(private http: HttpClient) { }

  /** GET faults from the server */
  getFaults (): Observable<Fault[]> {
    return this.http.get<Fault[]>(this.faultsUrl)
      .pipe(
        map((result : Fault[]) => {
          // you need to sort, if your data was not sorted
          return result.sort((a : Fault, b: Fault) => {
              if(a.createdAt > b.createdAt) return -1;
              if(a.createdAt < b.createdAt) return 1;
              return 0;
          });
        }),
        tap(_ => this.log('fetched faults')),
        catchError(this.handleError<Fault[]>('getFaults', []))
      )
  }

  /** GET team faults from the server */
  getTeamFaults (postName: string): Observable<Fault[]> {
    return this.http.get<Fault[]>(this.faultsUrl + postName)
      .pipe(
        map((result : Fault[]) => {
          // you need to sort, if your data was not sorted
          return result.sort((a : Fault, b: Fault) => {
              if(a.createdAt > b.createdAt) return -1;
              if(a.createdAt < b.createdAt) return 1;
              return 0;
          });
        }),
        tap(_ => this.log('fetched team faults')),
        catchError(this.handleError<Fault[]>('getTeamFaults', []))
      )
  }

  /** POST: add a new fault to the server */
  addFault(fault: Fault): Observable<Fault> {
    return this.http.post<Fault>(this.faultsUrl + 'upsert/', fault, httpOptions).pipe(
      tap((newfault: Fault) => this.log(`added fault w/ id=${newfault._id}`)),
      catchError(this.handleError<Fault>('addfault'))
    );
  }

  updateFault(fault: Fault): Observable<any> {
    delete fault['remark'];
    return this.http.post(this.faultsUrl + 'upsert/', fault, httpOptions).pipe(
      tap(_ => this.log(`updated fault id=${fault._id}`)),
      catchError(this.handleError<any>('updateFault'))
    );
  }

  getGeocode (location : string): Observable<Geocode> {
    return this.http.get<Geocode>(this.geocodeUrl + location)
      .pipe(
        tap(_ => this.log('fetched Geocode')),
        catchError(this.handleError<Geocode>('getGeocode'))
      )
  }
  
  private handleError<T> (operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
 
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead
 
      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);
 
      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  private log(message: string) {
    console.log(`FaultService: ${message}`);
  }
}
