import { Component, OnInit, Input, ElementRef, ViewChild } from '@angular/core';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { Fault } from '../fault';
import { FaultService } from '../fault.service';
import { getLocaleDateTimeFormat } from '@angular/common';
import { CommandService } from '../command.service';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { FileService } from '../file.service';
import { UploadedFile } from '../uploaded-file';
import { ToastrService } from 'ngx-toastr';
import { ToggleService } from '../toggle.service';
import { Toggle } from '../toggle';
import { FILE } from '../constants';

const clients = [ 'AFCD - 漁農自然護理署', 'AMS - 醫療輔助隊', 'ARCHSD - 建築署', 'AUDIT - 審計署', 'BD - 屋宇署', 'C&ED - 香港海關', 'C&SD - 政府統計處', 
  'CAD - 民航處', 'CAS - 民眾安全服務處', 'CEDBCCI - 商務及經濟發展局廣播及創意產業', 'CEDBCIT - 商務及經濟發展局', 'CEDD - 土木工程拓展署', 'CEOFFICE - 行政長官辦公室', 
  'CICS - 截取通訊及監察事務專員秘書處', 'CITBCI - 商務及經濟發展局常任秘書長(工商及旅遊)', 'CMAB - 政制及內地事務局', 'CSB - 政府公務員事務局', 
  'CSD - 香港懲教署', 'CSFSOFFICE - 政務司司長辦公室', 'CSTDI - 公務員培訓處', 'DEVBPL - 發展局 (規劃地政科)', 'DEVBW - 發展局 (工務科)', 
  'DH - 衞生署', 'DOFJ - 律政司', 'DSD - 渠務署', 'EDB - 教育局', 'EMSD - 機電工程署', 'ENB - 環境局', 'EPD - 環境保護署', 'FEHD - 食物環境衞生署', 
  'FHBF - 食物及衞生局 (食物)', 'FHBH - 食物及衞生局 (衞生)', 'FSD - 香港消防處', 'FSTBFS - 財經事務及庫務局 (財經事務)', 'FSTBTSY - 財經事務及庫務局 (庫務)', 
  'GFS - 政府飛行服務隊', 'GLD - 政府物流服務署', 'GOVTLAB - 政府化驗所', 'GPA - 政府產業署', 'GPANDQ - 政府產業署', 'HAB - 民政事務局', 'HAD - 民政事務總署 ', 
  'HKETO - 香港經濟貿易辦事處 (海外)', 'HKO - 天文台', 'HKPF - 香港警務處', 'HYD - 路政署', 'ICAC - 香港廉政公署', 'IMMD - 入境事務處', 'INVESTHK - 投資推廣署', 
  'IPD - 知識產權署', 'IRD - 稅務局', 'ISD - 政府新聞處', 'NULL', 'ITC - 創新科技署', 'JUDICI - 司法機構', 'LABOUR - 勞工處', 'LAD - 法律援助署', 'LANDSD - 地政總署', 
  'LCSD - 康樂及文化事務署', 'LWB - 勞工及福利局', 'MARINE - 海事處', 'OFCA-OFNAA - 電影、報刊及物品管理辦事處', 'OGCIO - 政府資訊科技總監辦公室', 
  'OLDCSB - 法定語文事務部', 'ORO - 破產管理署', 'PLAND - 規劃署', 'PSC - 公務員敍用委員會', 'REO - 選舉事務處', 'RTHK - 香港電台', 'RVD - 差餉物業估價署', 
  'SB - 保安局', 'SCCS - 薪諮會聯合秘書處', 'SWD - 社會福利署', 'SWD-LFU - 社會福利署 (政府獎券基金)', 'TC - 旅遊事務署 (商務及經濟發展局)', 'TD - 運輸署', 
  'THBH - 運輸及房屋局 (房屋)', 'THBT - 運輸及房屋局 (運輸)', 'TID - 工業貿易署', 'TRY - 庫務署', 'UGC - 大學教育資助委員會', 'WFSFAA - 學生資助事務處', 'WSD - 水務署',
];

@Component({
  selector: 'app-fault-detail',
  templateUrl: './fault-detail.component.html',
  styleUrls: ['./fault-detail.component.css'],
  providers: [NgbCarouselConfig]
})
export class FaultDetailComponent implements OnInit {
  message: any;
  public model: any;
  
  @Input() fault: Fault;

  @ViewChild('addPhoto')
  addPhotoInput: ElementRef;
  toggle: Toggle = new Toggle();
  uploading: boolean = false;
  disableUpsert: boolean = true;
  isToggle: boolean = true;

  constructor(config: NgbCarouselConfig, private faultService: FaultService, private commandService: CommandService, private fileService: FileService, private toast : ToastrService, private toggleService: ToggleService) { 
    config.interval = 0;
  }

  ngOnInit() {
    this.setDisable();
    this.getTyphoonToggle();
  }
  
  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => term.length < 2 ? []
        : clients.filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10))
    );

  saveFault() {

    if (!this.fault.category) {
      this.message = { text:'Please select category', type:'error' };
      return;
    }

    if (!this.fault.location) {
      this.message = { text:'Please enter location', type:'error' };
      return;
    }

    this.faultService.getGeocode(this.fault.location + ',hong kong')
    .subscribe(latlng => {
      // this.fault.lat = latlng.results[0]['locations'][0]['latLng']['lat']+'';
      // this.fault.lng = latlng.results[0]['locations'][0]['latLng']['lng']+'';

      this.fault.lat = latlng.SuggestedAddress[0]['Address']['PremisesAddress']['GeospatialInformation']['Latitude']+'';
      this.fault.lng = latlng.SuggestedAddress[0]['Address']['PremisesAddress']['GeospatialInformation']['Longitude']+'';

      if (!this.fault._id) {
        this.fault.faultNo = 'T000000103';
        this.fault.source = 'CCeP-Typhoon';
        let user = JSON.parse(localStorage.getItem('currentUser'));
        this.fault.createdBy = user.postInformation.uid;
        this.fault.lastUpdatedBy = user.postInformation.uid;
        this.fault.time = new Date();
        this.faultService.addFault(this.fault).subscribe(newFault => {
          this.fault = newFault;
          this.commandService.send('fault-created');
          this.toast.success(`Fault ${newFault.faultNo} Created`);
        });
      } else {
        this.fault.time = new Date();
        this.faultService.updateFault(this.fault).subscribe(() => {
          this.commandService.send('fault-updated');
          this.toast.success(`Fault ${this.fault.faultNo} Updated`);
        });
      }
    });
  }

  uploadPhoto($event) {
    if ($event.target.files.length > 0) {
      console.log('upload photo', $event.target.files);
      this.uploading = true;  // disable button

      // call api
      this.fileService.uploadFile($event.target.files[0]).subscribe((uploadedFiles : UploadedFile[]) => {
        console.log('uploadedFile', uploadedFiles);

        if (uploadedFiles.length > 0) {
          this.fault.attachmentIds.push(uploadedFiles[0].attachmentId);
        }

        this.addPhotoInput.nativeElement.value = '';  // clear file input
        this.uploading = false; // enable button after api call success
      });
    }
  }

  getImagePath(attachmentId : string) : string {
    if (attachmentId.indexOf('http') >= 0) {
      return attachmentId;
    }
    return FILE.URL + attachmentId;
  }

  countRows(text: String) {
    if (!text) {
      return 0;
    }
    return text.split("\n").length;
  }

  setDisable() {
    let userPermissions = JSON.parse(localStorage.getItem('userPermissions'));
    if(userPermissions) {

      userPermissions[0].permissions.forEach(permission => {
          if(permission === 'fault.upsert') {
            this.disableUpsert = false;
          } 
        }
      );
    }
    else {
      this.disableUpsert = true;
    }
  }

  getTyphoonToggle() {
    this.toggleService.getTyphoonToggle()
      .subscribe(toggle => {

        if (toggle) {
          this.isToggle = false;
        }
        else {
          this.isToggle = true; 
        }
      });
  }
}
