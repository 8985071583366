module.exports.API = {
    // DEV
    // URL: 'http://localhost:3001/api/'

    // PROD
    URL: 'https://typhoon.emsd.gov.hk/api/'
};

module.exports.FILE = {
    // DEV
    // URL: 'http://localhost:3002/file/'

    //PROD
    // URL: 'https://file-ccep.emsd.gov.hk/files/'
    URL: 'https://typhoon.emsd.gov.hk/file/'
};

module.exports.GEOCODE = {
    // URL: 'https://www.mapquestapi.com/geocoding/v1/address?',
    // KEY: 'jEL5yKdYQ8RGmPJF8TKO5ElBpl1IHaBv'
    URL: 'https://www.als.ogcio.gov.hk/lookup?q=',
    KEY: 'jEL5yKdYQ8RGmPJF8TKO5ElBpl1IHaBv'
};