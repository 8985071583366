import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { User } from './user';
import { map, tap, catchError } from 'rxjs/operators';
import { API } from './constants';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private usersUrl = API.URL + 'users/';

  errorMessage: any;

  constructor(private http: HttpClient) { }

  getUsers() : Observable<User[]> {
    return this.http.get<User[]>(this.usersUrl)
      .pipe(
        map((result : User[]) => {
          // you need to sort, if your data was not sorted
          return result.sort((a : User, b: User) => {
              if(a.postName < b.postName) return -1;
              if(a.postName > b.postName) return 1;
              return 0;
          });
        }),
        tap(_ => this.log('fetched users')),
        catchError(this.handleError<User[]>('getUsers', []))
      )
  }

  getRmUsers() : Observable<User[]> {
    return this.http.get<User[]>(this.usersUrl + 'rm/')
      .pipe(
        map((result : User[]) => {
          // you need to sort, if your data was not sorted
          return result.sort((a : User, b: User) => {
              if(a.postName < b.postName) return -1;
              if(a.postName > b.postName) return 1;
              return 0;
          });
        }),
        tap(_ => this.log('fetched Rms')),
        catchError(this.handleError<User[]>('getRmUsers', []))
      )
  }

  createUser(user: User) : Observable<{} | User> {
    return this.http.post<User>(this.usersUrl + 'create/', user, httpOptions)
      .pipe(
        tap((newUser: User) => this.log(`Created user =${newUser._id}`)),
        catchError(err => {
          switch(err.status) {
            case 404:
              this.errorMessage = 'User already exists';
              break;
            default:
              this.errorMessage = err.error.message || err.statusText;
          }
          return this.errorMessage;
        })
        // catchError(this.handleError<User>('createUser'))
      );
  }

  updateUser(user: User): Observable<any> {
    return this.http.post(this.usersUrl + 'upsert/', user, httpOptions).pipe(
      tap(_ => this.log(`updated user postName=${user.postName}`)),
      catchError(this.handleError<any>('updateUser'))
    );
  }

  readyUser(user: User): Observable<any> {
    return this.http.post(this.usersUrl + 'ready/', user, httpOptions).pipe(
      tap(_ => this.log(`updated user postName=${user.postName}`)),
      catchError(this.handleError<any>('updateUser'))
    );
  }

  removeUser(user: User) : Observable<User> {
    return this.http.post<User>(this.usersUrl + 'remove/' + user._id, {})
      .pipe(
        tap(_ => this.log(`Removed user =${user._id}`)),
        catchError(this.handleError<User>('removeUser'))
      );
  }

  getUserPermissions(postName) : Observable<User> {
    return this.http.get<User>(this.usersUrl + postName)
      .pipe(
        tap(_ => this.log('fetched use permissions')),
        catchError(this.handleError<User>('getUserPermissions'))
      );
  }

  private handleError<T> (operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
 
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead
 
      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);
 
      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  private log(message: string) {
    console.log(`UserService: ${message}`);
  }
}
