import { Component, OnChanges, OnInit } from '@angular/core';
import { CommandService } from '../command.service';
import { ToggleService } from '../toggle.service';
import { Toggle } from '../toggle';
import { AuthService } from '../auth/auth.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit, OnChanges {

  toggle: Toggle = new Toggle();
  postName: string;
  isToggle: boolean = true;

  constructor(private commandService: CommandService, private toggleService: ToggleService, private authService: AuthService) { }

  ngOnInit() {
    this.getTyphoonToggle();
    this.getPostName();
  }

  getPostName() {
    this.postName = this.authService.getUserPost();
  }
  
  ngOnChanges() {
    this.getTyphoonToggle();
  }

  getTyphoonToggle() {
    this.toggleService.getTyphoonToggle()
      .subscribe(toggle => {

        if (toggle) {
          this.isToggle = false;
        }
        else {
          this.isToggle = true;
        }
      });
  }

  createFault() {
    this.commandService.send('create-fault');
  }

  logout() {
    this.authService.logout();
  }
}
