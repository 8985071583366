import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { Column } from './column';

const excelType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const excelExtension = '.xlsx';

@Injectable({
  providedIn: 'root'
})
export class ExcelService {

  constructor() { }
  
  public exportAsExcelFile(json: any[], excelFileName: string, columns :Column[]): void {
    let filteredJson = this.filterAndTransformColumns(json, columns);
    let header = this.getHeader(columns);
    let wscols = this.getWorkSheetColumns(columns);

    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(filteredJson, { header:header, cellDates:true });
    worksheet['!cols'] = wscols;

    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  getWorkSheetColumns(columns: Column[]) : object[] {
    return columns.map(function (col) {
      return { width:col.columnWidth };
    });
  }

  getHeader(columns: Column[]) : string[] {
    return columns.map(function (col) {
      return col.headerText;
    });
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: excelType
    });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + excelExtension);
  }

  filterAndTransformColumns(rows: any[], columns: Column[]) : any[] {
    let map : object = this.getColumnsFieldNameHeaderTextMap(columns);
    let result = [];
    
    for(let i = 0; i < rows.length; i++) {
      let row  = rows[i];
      let clonedRow = {};
      for(let key in row) {
        if (key in map) {
          let headerText = map[key];
          clonedRow[headerText] = row[key];
        }
      }

      result.push(clonedRow);
    }
    return result;
  }

  getColumnsFieldNameHeaderTextMap(columns: Column[]): object {
    let result = {};
    
    for(let i = 0; i < columns.length; i++) {
      result[columns[i].fieldName] = columns[i].headerText;
    }

    return result;
  }

}
