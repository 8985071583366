import { Component, OnInit, HostListener } from '@angular/core';
import { Fault } from '../fault';
import { FaultService } from '../fault.service';
import { CommandService } from '../command.service';
import { Subscription, Observable, Subject, interval } from 'rxjs';
import { FormControl } from '@angular/forms';
import { startWith, map, debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { AuthService } from '../auth/auth.service';
import { formatDate } from '@angular/common';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { ExcelService } from '../excel/excel.service';
import { Column } from '../excel/column';

@Component({
  selector: 'app-faults',
  templateUrl: './faults.component.html',
  styleUrls: ['./faults.component.css'],
  animations: [
    trigger('savingSaved', [
      state('saving', style({
        color: '#ff0000'
      })),
      state('saved', style({
        color: '#6c757d'
      })),
      transition('saved => saving', [
        animate('0s')
      ]),
      transition('saving => saved', [
        animate('1.5s')
      ])
    ]),
  ]
})

export class FaultsComponent implements OnInit {
  faults: Fault[] = [];
  faults$: Observable<Fault[]>;
  page: number = 0;
  pageSize: number = 100;
  dirty: boolean = false;
  state: String = 'list';
  selectedFault: Fault = null;
  commandSubscription: Subscription;
  filter = new FormControl('');
  pendingSaveEngineerComments = new Map<Fault, Subject<string>>();
  newInternalComments: object = {};
  focusedCommentsFault: Fault = null;
  newInternalCommentDirty: boolean = false;
  isInternalSavings = new Map<Fault, boolean>();
  isEngineerSavings = new Map<Fault, boolean>();
  disableWatch: boolean = true;
  disableEngineer: boolean = true;
  disableUpsert: boolean = true;
  updateSubscription: Subscription;
  faultCount: number = 0;
  showCount: boolean = false;

  showScroll: boolean;
  showScrollHeight = 300;
  hideScrollHeight = 10;
  
  @HostListener('window:scroll', [])
  onWindowScroll() {
    if (( window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop) > this.showScrollHeight) {
        this.showScroll = true;
    } 
    else if ( this.showScroll && (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop) < this.hideScrollHeight) { 
      this.showScroll = false; 
    }
  }

  scrollToTop() { 
    (function smoothscroll() { 
      var currentScroll = document.documentElement.scrollTop || document.body.scrollTop; 
      if (currentScroll > 0) {
        window.requestAnimationFrame(smoothscroll);
        window.scrollTo(0, currentScroll - (currentScroll / 5));
      }
    })();
  }

  constructor(private faultService: FaultService, private commandService: CommandService, private authService: AuthService, private excelService: ExcelService) { 
    this.faults$ = this.filter.valueChanges.pipe(
      startWith(''),
      map(text => this.search(text))
    );

    this.commandSubscription = this.commandService.commands$.subscribe(
      command => {
        switch(command) {
          case 'create-fault':
            this.state = 'detail';
            this.selectedFault = new Fault();
            break;
          case 'fault-created':
          case 'fault-updated':
            this.state = 'list';
            this.getFaults();
            this.countFaults();
            break;
        }
      }
    )

    this.updateSubscription = interval(10000).subscribe(
        (val) => { this.countFaults(); }
      )
  }
  
  ngOnInit() {
    this.getFaults();
    this.setDisable();
    this.countFaults();
  }
  
  ngOnDestroy() {
    this.updateSubscription.unsubscribe();
  }

  getFaults(): void {
    // let userPermissions = JSON.parse(localStorage.getItem('userPermissions'));
    // if(userPermissions && userPermissions.length > 0) {
    //   if(userPermissions[0].role === 'SI/I' || userPermissions[0].role === 'RM') {
    //     this.faultService.getTeamFaults(userPermissions[0].postName)
    //     .subscribe(faults => {
    //       this.faults = faults;
    //       this.filter.setValue('');
    //     });
    //   }
    //   else {
    //     this.faultService.getFaults()
    //     .subscribe(faults => {
    //       this.faults = faults;
    //       this.filter.setValue('');
    //     });
    //   }
    // }
    // else {
      this.faultService.getFaults()
      .subscribe(faults => {
        this.faults = faults;
        this.filter.setValue('');
      });
    // }
  }

  countFaults(): void {
    // let userPermissions = JSON.parse(localStorage.getItem('userPermissions'));
    // if(userPermissions && userPermissions.length > 0) {
    //   if(userPermissions[0].role === 'SI/I' || userPermissions[0].role === 'RM') {
    //     this.faultService.getTeamFaults(userPermissions[0].postName)
    //     .subscribe(faults => {
    //       this.faultCount = faults.length - this.faults.length;          
    //       if (this.faultCount === 0) {
    //         this.showCount = false;
    //       }
    //       else {
    //         this.showCount = true;
    //       }
    //     });
    //   }
    //   else {
    //     this.faultService.getFaults()
    //     .subscribe(faults => {
    //       this.faultCount = faults.length - this.faults.length;
    //       if (this.faultCount === 0) {
    //         this.showCount = false;
    //       }
    //       else {
    //         this.showCount = true;
    //       }
    //     });
    //   }
    // }
    // else {
      this.faultService.getFaults()
      .subscribe(faults => {
        this.faultCount = faults.length - this.faults.length;
        if (this.faultCount === 0) {
          this.showCount = false;
        }
        else {
          this.showCount = true;
        }
      });
    // }
  }

  showFault(fault: Fault) : void {
    this.state = 'detail';
    this.selectedFault = new Fault(fault);
  }

  hideFault() : void {
    this.state = 'list';
    this.selectedFault = null;
  }

  setDirty() : void {
    this.dirty = true;
  }

  clearDirty() : void {
    this.dirty = false;
  }

  countRows(text: String) {
    if (!text) {
      return 0;
    }
    return text.split("\n").length;
  }

  toggleWatch(fault: Fault) : void {
    fault.engineerMajorFault = !fault.engineerMajorFault;

    this.faultService.updateFault(fault).subscribe();
  }

  trash(fault: Fault) : void {
    fault.isActive = false;

    this.faultService.updateFault(fault).subscribe(() => {
      this.refresh();
    });
    
  }

  search(text: string): Fault[] {
    return this.faults.filter(fault => {
      this.page = 0;
      const term = text.toLowerCase();
      return (fault.faultNo && fault.faultNo.toLowerCase().includes(term))
          || (fault.category && fault.category.toLowerCase().includes(term))
          // || (fault.client && fault.client.toLowerCase().includes(term))
          || (fault.client && fault.client.toLowerCase() == term)
          || (fault.location && fault.location.toLowerCase().includes(term))
          || (fault.createdBy && fault.createdBy.toLowerCase().includes(term))
          || (fault.internalComment && fault.internalComment.toLowerCase().includes(term))
          || (fault.engineerComment && fault.engineerComment.toLowerCase().includes(term))
          || (String(fault.majorFault ? 'Yes' : 'No').toLowerCase().includes(term))
          || (fault.description && fault.description.toLowerCase().includes(term));
    });
  }

  saveEngineerComment(fault: Fault) : void {
    let comments : Subject<string>;

    if (!this.pendingSaveEngineerComments.has(fault)) {
      comments = new Subject<string>()

      this.pendingSaveEngineerComments.set(fault, comments);

      comments.pipe(
        debounceTime(300),
        distinctUntilChanged())
      .subscribe(() => {
        fault.engineerLastUpdateTime = new Date();
        fault.engineerCommentBy = this.authService.getUserPost();
        this.isEngineerSavings[fault._id] = true;
        this.faultService.updateFault(fault).subscribe(() => {
          console.log('engineer comment saved');
          this.isEngineerSavings[fault._id] = false;
        });
      });
    } else {
      comments = this.pendingSaveEngineerComments.get(fault);
    }

    comments.next(fault.engineerComment);
  }

  saveEngineerStatus(fault: Fault) : void {
    fault.engineerLastUpdateTime = new Date();
    fault.engineerCommentBy = this.authService.getUserPost();
    this.isEngineerSavings[fault._id] = true;
    this.faultService.updateFault(fault).subscribe(() => {
      console.log('engineer status saved');
      this.isEngineerSavings[fault._id] = false;
    });
  }

  getInteralComments(fault: Fault) : string[] {
    if (!fault.internalComment) {
      return [];
    }

    return fault.internalComment.split('\n');
  }

  appendInternalComment(fault: Fault) : void {
    if (!this.newInternalComments[fault._id] || this.newInternalComments[fault._id] == '') {
      return;
    }

    if (!fault.internalComment || fault.internalComment == '') {
      fault.internalComment = this.newInternalComments[fault._id];
    } else {
      fault.internalComment += '\n' + this.newInternalComments[fault._id];
    }
    this.newInternalComments[fault._id] = '';
    fault.internalCommentBy = this.authService.getUserPost();
    fault.internalLastUpdateTime = new Date();
    this.isInternalSavings[fault._id] = true;
    this.faultService.updateFault(fault).subscribe(() => {
      this.isInternalSavings[fault._id] = false;
    });
  }

  saveInternalStatus(fault: Fault) : void {
    fault.internalLastUpdateTime = new Date();
    fault.internalCommentBy = this.authService.getUserPost();
    this.isInternalSavings[fault._id] = true;
    this.faultService.updateFault(fault).subscribe(() => {
      this.isInternalSavings[fault._id] = false;
    });
  }

  onNewInternalCommentFocus(fault: Fault) : void {
    if (!this.newInternalComments[fault._id] || this.newInternalComments[fault._id] == '') {
      this.newInternalComments[fault._id] = '[' + formatDate(new Date, 'd/M HH:mm', 'en-US') + '] ';
      this.newInternalCommentDirty = false;
    } else {
      this.newInternalCommentDirty = true;
    }

    this.focusedCommentsFault = fault;
  }

  onNewInternalCommentBlur(fault: Fault) : void {
    if (!this.newInternalCommentDirty) {
      this.newInternalComments[fault._id] = '';
    }
    this.focusedCommentsFault = null;
    this.newInternalCommentDirty = false;
  }

  onNewInternalCommentChange(fault: Fault) : void {
    if (fault._id == this.focusedCommentsFault._id) {
      this.newInternalCommentDirty = true;
    }
  }

  exportExcel() : void {
    let headers: Column[] = [
      new Column('faultNo', 'Fault No.', 15),
      new Column('time', 'Date', 25),
      new Column('source', 'Source', 18),
      new Column('category', 'Category', 18),
      new Column('client', 'Client', 36),
      new Column('location', 'Location', 28),
      new Column('faultCount', 'Fault Counted', 15),
      new Column('description', 'Description', 61),
      new Column('internalComment', 'Internal Comment', 18),
      new Column('internalCommentBy', 'Internal Comment By', 20),
      new Column('engineerMajorFault', 'Major Fault', 12),
      new Column('engineerStatus', 'Status', 12),
      new Column('engineerComment', 'Engineer Comment', 18),
      new Column('engineerCommentBy', 'Engineer Comment By', 20),
      new Column('createdAt', 'Reported Time', 25),
      new Column('updatedAt', 'Last Update Time', 25)
    ];

    let data = this.cloneAndFormat(this.faults);
    console.log(data);
    this.excelService.exportAsExcelFile(data, 'fulllist', headers);
  }

  cloneAndFormat(faults: Fault[])  {
    return faults.map(function (fault) {
      let clone = {};
      for(let key in fault) {
        switch (key) {
          case 'time':
          case 'createdAt':
          case 'updatedAt':
            clone[key] = formatDate(fault[key], 'MMM d, yyyy h:mm aa', 'en-US');
            break;
          default:
            clone[key] = fault[key];
        }
      }
      return clone;
    });
  }

  refresh(): void {
    this.ngOnInit();
  }

  setDisable() {
    let userPermissions = JSON.parse(localStorage.getItem('userPermissions'));
    if(userPermissions) {

      userPermissions[0].permissions.forEach(permission => {
          if(permission === 'fault.watch') {
            this.disableWatch = false;
          } 
          else if(permission === 'fault.engineer') {
            this.disableEngineer = false;
          }
          else if(permission === 'fault.upsert') {
            this.disableUpsert = false;
          }
        }
      );
    }
    else {
      this.disableWatch = true;
      this.disableEngineer = true;
      this.disableUpsert = true;
    }
  }
}
