import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { Toggle } from './toggle';
import { tap, catchError } from 'rxjs/operators';
import { API } from './constants';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class ToggleService {

  private typhoonToggleUrl = API.URL + 'toggles/typhoon/';
  private currentToggleUrl = API.URL + 'toggles/';
  private toggleStartUrl = API.URL + 'toggles/start/';
  private toggleStopUrl = API.URL + 'toggles/stop/';

  constructor(private http: HttpClient) { }

  getActiveToggle() : Observable<Toggle> {
    return this.http.get<Toggle>(this.currentToggleUrl).pipe(
      tap(_ => this.log(`fetched current toggle`)),
      catchError(this.handleError<Toggle>(`getActiveToggle`))
    );
  }

  getTyphoonToggle() : Observable<Toggle> {
    return this.http.get<Toggle>(this.typhoonToggleUrl).pipe(
      tap(_ => this.log(`fetched typhoon toggle`)),
      catchError(this.handleError<Toggle>(`getTyphoonToggle`))
    );
  }

  startToggle(startBy: string, startNew: boolean) : Observable<Toggle> {
    return this.http.post<Toggle>(this.toggleStartUrl, { startBy: startBy, startNew: startNew }, httpOptions).pipe(
      tap((startedToggle: Toggle) => {this.log(`started toggle w/ startBy=${startBy} startNew=${startNew}`);location.reload();} ),
      catchError(this.handleError<Toggle>('startToggle'))
    );
  }

  stopToggle(endBy: string, typhoonName: string) : Observable<Toggle> {
    return this.http.post<Toggle>(this.toggleStopUrl, { endBy: endBy, typhoonName: typhoonName }, httpOptions).pipe(
      tap((endedToggle: Toggle) => {this.log(`stopped toggle w/ endBy=${endBy} typhoonName=${typhoonName}`);location.reload();} ),
      catchError(this.handleError<Toggle>('stopToggle'))
    );
  }

  private log(message: string) {
    console.log(`FaultService: ${message}`);
  }

  private handleError<T> (operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
 
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead
 
      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);
 
      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
