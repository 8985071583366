import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { Comment } from './comment';
import { map, tap, catchError } from 'rxjs/operators';
import { API } from './constants';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class CommentService {

  private commentsUrl = API.URL + 'comments/';

  errorMessage: any;

  constructor(private http: HttpClient) { }

  getComments() : Observable<Comment[]> {
    return this.http.get<Comment[]>(this.commentsUrl)
      .pipe(
        map((result : Comment[]) => {
          // you need to sort, if your data was not sorted
          return result.sort((a : Comment, b: Comment) => {
              if(a.time > b.time) return -1;
              if(a.time < b.time) return 1;
              return 0;
          });
        }),
        tap(_ => this.log('fetched comments')),
        catchError(this.handleError<Comment[]>('getComments', []))
      )
  }

  createComment(comment: Comment) : Observable<{} | Comment> {
    return this.http.post<Comment>(this.commentsUrl + 'create/', comment, httpOptions)
      .pipe(
        tap((newComment: Comment) => this.log(`Created comment =${newComment._id}`)),
        catchError(err => {
          switch(err.status) {
            case 404:
              this.errorMessage = 'Comment already exists';
              break;
            default:
              this.errorMessage = err.error.message || err.statusText;
          }
          return this.errorMessage;
        })
        // catchError(this.handleError<User>('createUser'))
      );
  }

  private handleError<T> (operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
 
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead
 
      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);
 
      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  private log(message: string) {
    console.log(`UserService: ${message}`);
  }
}
