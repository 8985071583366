import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../auth/auth.service';
import { UserService } from '../user.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  message: any;
  postName: string;
  password: string;

  constructor(public authService: AuthService, private router: Router, private userService: UserService) { }
  

    
  
  
//   toggleShowPassword() {
//   var app = angular.module('plunker', ['ui.bootstrap']);

//   app.controller('MainCtrl', function($scope) {
//       $scope.params = {};
//       $scope.showPassword = false;
//       $scope.toggleShowPassword = function() {
//           $scope.showPassword = !$scope.showPassword;
//       }
//   });
// }

  ngOnInit() {
    if (this.authService.isLoggedIn()) {

      // Get the redirect URL from our auth service
      // If no redirect has been set, use the default
      let redirect = this.authService.redirectUrl ? this.router.parseUrl(this.authService.redirectUrl) : '/faults';

      // Redirect the user
      this.router.navigateByUrl(redirect);
    }
  }

  login() {
    if (!this.postName) {
      this.message = { text:'Please input Staff Name', type:'error' };
      return;
    }

    if (!this.password) {
      this.message = { text:'Please enter password', type:'error' };
      return;
    }

    this.message = { text:'Trying to log in ...', type:'info' };
 
    this.authService.login(this.postName, this.password).subscribe(() => {
      this.setMessage();
      if (this.authService.isLoggedIn()) {

        // Get the redirect URL from our auth service
        // If no redirect has been set, use the default
        let redirect = this.authService.redirectUrl ? this.router.parseUrl(this.authService.redirectUrl) : '/faults';
 
        // Redirect the user
        this.router.navigateByUrl(redirect);
      } else {
        this.setMessage()
      }
    });

    this.userService.getUserPermissions(this.postName.toUpperCase())
      .subscribe(userPermissions => {
        if (userPermissions) {
          localStorage.setItem('userPermissions', JSON.stringify(userPermissions));
        }
      });
  }

  setMessage() {
    if (this.authService.isLoggedIn()) {
      this.message = { text:'Logged in', type:'success' };
    } else {
      this.message = { text:this.authService.errorMessage, type:'error' };
    }
  }
  
  logout() {
    this.authService.logout();
    this.setMessage();
  }
}
