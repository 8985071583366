import { Component, OnInit, Input, ElementRef, ViewChild } from '@angular/core';
import { User } from '../user';
import { UserService } from '../user.service';
import { CommandService } from '../command.service';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-account-detail',
  templateUrl: './account-detail.component.html',
  styleUrls: ['./account-detail.component.css']
})
export class AccountDetailComponent implements OnInit {
  message: any;
  public model: any;
  rms: User[] = [];
  @Input() user: User;

  constructor(private userService: UserService, private commandService: CommandService, private toast : ToastrService) { }

  ngOnInit() {
    this.getRms();
  }

  saveAccount() {
    let currentUser = JSON.parse(localStorage.getItem('currentUser'));

    if (currentUser.postInformation.uid != this.user.postName) {
      this.message = { text:'You can only update your own account', type:'error' };
      return;
    }

    this.userService.updateUser(this.user).subscribe(() => {
      this.commandService.send('account-updated');
      this.toast.success(`User ${this.user.postName} Updated`);
    });
  }

  readyUser() {
    if(this.user.isReady == 'YES') {
      this.user.isReady = 'NO';
    }
    else {
      this.user.isReady = 'YES';
    }

    this.userService.readyUser(this.user).subscribe(() => {
      this.commandService.send('account-updated');
      this.toast.success(`User ${this.user.postName} Updated`);
    });
  }

  getRms() {
    this.userService.getRmUsers()
      .subscribe(rms => {
        if (rms) {
          this.rms = rms;
        }
      });
  }

}
