import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CommandService {
  private commands = new Subject<string>();

  commands$ = this.commands.asObservable();

  constructor() { }

  send(command: string) {
    this.commands.next(command);
  }
}
