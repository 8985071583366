export class Fault {
    _id: string;
    faultNo: string;
    description: string;
    status: string;
    category: string;
    location: string;
    faultCount: number;
    majorFault: boolean;
    internalComment: string;
    internalCommentBy: string;
    internalLastUpdateTime: Date;
    createdAt: Date;
    createdBy: string;
    source: string;
    client: string;
    engineerComment: string;
    engineerCommentBy: string;
    updatedAt: Date;
    lastUpdatedBy: string;
    engineerMajorFault: boolean;
    engineerStatus: string;
    engineerLastUpdateTime: Date;
    isActive: boolean;
    time: Date;
    lat: string;
    lng: string;
    attachmentIds: string[];
    
    constructor(copy?: Fault) {
        this.faultCount = 1;
        this.attachmentIds = [];

        if (copy) {
            this.cloneFrom(copy);
        }
    }

    private cloneFrom(copy: Fault) {
        this._id = copy._id;
        this.faultNo = copy.faultNo;
        this.description = copy.description;
        this.status = copy.status;
        this.category = copy.category;
        this.location = copy.location;
        this.faultCount = copy.faultCount;
        this.majorFault = copy.majorFault;
        this.internalComment = copy.internalComment;
        this.internalCommentBy = copy.internalCommentBy;
        this.internalLastUpdateTime = copy.internalLastUpdateTime;
        this.createdAt = copy.createdAt;
        this.createdBy = copy.createdBy;
        this.source = copy.source;
        this.client = copy.client;
        this.engineerComment = copy.engineerComment;
        this.engineerCommentBy = copy.engineerCommentBy;
        this.updatedAt = copy.updatedAt;
        this.lastUpdatedBy = copy.lastUpdatedBy;
        this.engineerMajorFault = copy.engineerMajorFault;
        this.engineerStatus = copy.engineerStatus;
        this.engineerLastUpdateTime = copy.engineerLastUpdateTime;
        this.isActive = copy.isActive;
        this.time = copy.time;
        this.lat = copy.lat;
        this.lng = copy.lng;

        if (copy.attachmentIds) {
            for(let i = 0; i < copy.attachmentIds.length; i++) {
                this.attachmentIds[i] = copy.attachmentIds[i];
            }
        }
    }
}