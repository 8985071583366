import { Component, OnInit, OnChanges, SimpleChanges } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ToggleService } from "../toggle.service";
import { Toggle } from "../toggle";
import { User } from "../user";
import { AuthService } from "../auth/auth.service";
import { ArchiveService } from "../archive.service";
import { Archive } from "../Archive";
import { ArchiveFault } from "../archiveFault";
import { formatDate } from "@angular/common";
import { ExcelService } from "../excel/excel.service";
import { Column } from "../excel/column";

@Component({
  selector: "app-system",
  templateUrl: "./system.component.html",
  styleUrls: ["./system.component.css"],
})
export class SystemComponent implements OnInit, OnChanges {
  toggle: Toggle = new Toggle();
  user: User = new User();
  confirmWord: string = "";
  invalidConfirmWord: boolean = false;
  invalidTyphoonName: boolean = false;
  startNew: boolean = false;
  archives: Archive[] = [];
  archiveFaults: ArchiveFault[] = [];
  disableToggle: boolean = true;
  disableArchive: boolean = true;
  upt: boolean = false;

  constructor(
    private modalService: NgbModal,
    private toggleService: ToggleService,
    private authService: AuthService,
    private archiveService: ArchiveService,
    private excelService: ExcelService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {}

  ngOnInit() {
    this.getActiveToggle();
    this.getArchives();
    this.setDisable();
  }

  getArchives() {
    this.archiveService.getArchives().subscribe((archives) => {
      if (archives) {
        this.archives = archives;
      }
    });
  }

  getActiveToggle() {
    this.toggleService.getActiveToggle().subscribe((toggle) => {
      if (toggle) {
        this.toggle = toggle;
      }
    });
  }

  getTogglePeriod(startDate, endDate): string {
    if (startDate == null) {
      return "";
    }

    let periodEndTime: number = Date.now();
    if (endDate != null) {
      periodEndTime = new Date(endDate).getTime();
    }

    let seconds: number = Math.floor(
      (periodEndTime - new Date(startDate).getTime()) / 1000
    );
    let minutes: number = Math.floor(seconds / 60);
    let hours: number = Math.floor(minutes / 60);
    minutes -= hours * 60;

    let hourText: string = `${hours} Hour`;
    if (hours > 1) {
      hourText += "s";
    }

    let minuteText: string = `${minutes} Minute`;
    if (minutes > 1) {
      minuteText += "s";
    }

    return `${hourText} ${minuteText}`;
  }

  toggleTyphoon(event, confirmContent, typhoonNameContent) {
    this.clearInvalid();
    event.srcElement.blur(); // prevent chrome error due to different event sequences of different browsers
    if (!this.toggle.typhoonToggle) {
      this.confirmWord = "";
      this.invalidConfirmWord = false;
      this.modalService
        .open(confirmContent, { backdrop: "static" })
        .result.then(
          (result) => {
            console.log("result", result, "this.confirmWord", this.confirmWord);
            this.toggleService
              .startToggle(this.authService.getUserPost(), this.startNew)
              .subscribe((startedToggle) => {
                this.toggle = startedToggle;
              });
          },
          (reason) => {
            console.log("reason", reason);
            this.toggle.typhoonToggle = false;
          }
        );
    } else {
      this.modalService
        .open(typhoonNameContent, { backdrop: "static" })
        .result.then(
          (result) => {
            console.log(
              "result",
              result,
              "this.typhoonName",
              this.toggle.typhoonName
            );
            console.log("getUserPost", this.authService.getUserPost());
            this.toggleService
              .stopToggle(
                this.authService.getUserPost(),
                this.toggle.typhoonName
              )
              .subscribe((stoppedToggle) => {
                this.toggle = stoppedToggle;
              });
          },
          (reason) => {
            console.log("reason", reason);
            this.toggle.typhoonToggle = true;
          }
        );
    }
  }

  startToggle(modal, startNew) {
    console.log("modal", modal, "this.confirmWord", this.confirmWord);
    if (this.confirmWord == "CONFIRM") {
      this.startNew = startNew;
      this.upt = !this.upt;
      modal.close("Start");
    } else {
      this.invalidConfirmWord = true;
    }
  }

  stopToggle(modal) {
    console.log(
      "modal",
      modal,
      "this.toggle.typhoonName",
      this.toggle.typhoonName
    );
    if (
      this.toggle.typhoonName != null &&
      this.toggle.typhoonName.trim() != ""
    ) {
      modal.close("Stop");
    } else {
      this.invalidTyphoonName = true;
    }
  }

  clearInvalid() {
    this.invalidConfirmWord = false;
    this.invalidTyphoonName = false;
  }

  archive() {
    this.archiveService.archive().subscribe(() => {
      this.getActiveToggle();
      this.getArchives();
      this.toggle = new Toggle();
    });
  }

  exportExcel(archive: Archive) {
    this.archiveService
      .getArchiveFault(archive.typhoonName)
      .subscribe((archiveFaults) => {
        if (archiveFaults) {
          this.archiveFaults = archiveFaults;

          let headers: Column[] = [
            new Column("typhoonName", "Typhoon Name", 15),
            new Column("faultNo", "Fault No.", 15),
            new Column("time", "Date", 25),
            new Column("source", "Source", 18),
            new Column("category", "Category", 18),
            new Column("client", "Client", 36),
            new Column("location", "Location", 28),
            new Column("faultCount", "Fault Counted", 15),
            new Column("description", "Description", 61),
            new Column("internalComment", "Internal Comment", 18),
            new Column("internalCommentBy", "Internal Comment By", 20),
            new Column("engineerMajorFault", "Major Fault", 12),
            new Column("engineerStatus", "Status", 12),
            new Column("engineerComment", "Engineer Comment", 18),
            new Column("engineerCommentBy", "Engineer Comment By", 20),
            new Column("createdAt", "Reported Time", 25),
            new Column("updatedAt", "Last Update Time", 25),
          ];

          let data = this.cloneAndFormat(this.archiveFaults);
          console.log(data);
          this.excelService.exportAsExcelFile(data, "fulllist", headers);
        }
      });
  }

  cloneAndFormat(archiveFaults: ArchiveFault[]) {
    return archiveFaults.map(function (archiveFault) {
      let clone = {};
      for (let key in archiveFault) {
        switch (key) {
          case "createdAt":
          case "updatedAt":
            clone[key] = formatDate(
              archiveFault[key],
              "MMM d, yyyy h:mm aa",
              "en-US"
            );
            break;
          default:
            clone[key] = archiveFault[key];
        }
      }
      return clone;
    });
  }

  setDisable() {
    let userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
    if (userPermissions) {
      userPermissions[0].permissions.forEach((permission) => {
        if (permission === "system.toggle") {
          this.disableToggle = false;
        } else if (permission === "system.archive") {
          this.disableArchive = false;
        }
      });
    } else {
      this.disableToggle = true;
      this.disableArchive = true;
    }
  }
}
