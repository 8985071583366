export class Toggle {
    _id: string;
    typhoonName: string;
    typhoonToggle: boolean;
    startDate: Date;
    startBy: string;
    endDate: Date;
    endBy: string;
    isActive: boolean;
    createdBy: string;
    lastUpdatedBy: string;
}