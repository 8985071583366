export class User {
    _id: string;
    postName: string;
    telephone: string;
    role: string;
    supervisor: string;
    isReady: string;
    isActive: boolean;
    createdBy: string;
    lastUpdatedBy: string;
    permissions: string[];

    constructor(copy?: User) {
        
        if (copy) {
            this.cloneFrom(copy);
        }
    }

    private cloneFrom(copy: User) {
        this._id = copy._id;
        this.postName = copy.postName;
        this.telephone = copy.telephone;
        this.role = copy.role;
        this.supervisor = copy.supervisor;
        this.isReady = copy.isReady;
        this.isActive = copy.isActive;
        this.createdBy = copy.createdBy;
        this.lastUpdatedBy = copy.lastUpdatedBy;
        this.permissions = copy.permissions;
        // if (copy.permissions) {
        //     for(let i = 0; i < copy.permissions.length; i++) {
        //         this.permissions[i] = copy.permissions[i];
        //     }
        // }
    }
}