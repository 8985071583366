import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { tap, delay, map, catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { API } from '../constants';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private authUrl = API.URL + 'login/';

  // store the URL so we can redirect after logging in
  redirectUrl: string;
  errorMessage: any;

  constructor(private http: HttpClient) { }

  login(postName : string, password: string): Observable<boolean> {
    return this.http.post<any>(this.authUrl, { postName: postName.toUpperCase(), password: password }).pipe(
      map(user => {
        if (user && user.token) {
          localStorage.setItem('currentUser', JSON.stringify(user));
        }
        return user;
      }),
      catchError(err => {
        this.logout();
        switch(err.status) {
          case 401:
            this.errorMessage = 'Wrong Staff Name or Password';
            break;
          default:
            this.errorMessage = err.error.message || err.statusText;
        }
        return this.errorMessage;
      })
    );
  }

  logout(): void {
    localStorage.removeItem('currentUser');
    localStorage.removeItem('userPermissions');
  }

  isLoggedIn() : boolean {
    return !!localStorage.getItem('currentUser');
  }

  getUserPost() : string {
    if (!this.isLoggedIn()) {
      return null;
    }

    let user = JSON.parse(localStorage.getItem('currentUser'));
    console.log('user', user);
    return user.postInformation.uid;
  }
}
