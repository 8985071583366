import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { Archive } from './Archive';
import { ArchiveFault } from './archiveFault';
import { map, tap, catchError } from 'rxjs/operators';
import { Fault } from './fault';
import { API } from './constants';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class ArchiveService {

  private archivesUrl = API.URL + 'archives/';

  constructor(private http: HttpClient) { }

  archive() : Observable<Archive> {
    return this.http.post<Archive>(this.archivesUrl + 'data/', {})
      .pipe(
        tap(_ => this.log('archived')),
        catchError(this.handleError<Archive>('archive'))
      );
  }

  getArchives() : Observable<Archive[]> {
    return this.http.get<Archive[]>(this.archivesUrl)
      .pipe(
        map((result : Archive[]) => {
          // you need to sort, if your data was not sorted
          return result.sort((a : Archive, b: Archive) => {
              if(a.endDate > b.endDate) return -1;
              if(a.endDate < b.endDate) return 1;
              return 0;
          });
        }),
        tap(_ => this.log('fetched archives')),
        catchError(this.handleError<Archive[]>('getArchives', []))
      )
  }

  getArchiveFault(typhoonName: string) : Observable<ArchiveFault[]> {
    return this.http.get<ArchiveFault[]>(this.archivesUrl + typhoonName)
      .pipe(
        map((result : ArchiveFault[]) => {
          // you need to sort, if your data was not sorted
          return result.sort((a : ArchiveFault, b: ArchiveFault) => {
              if(a.createdAt > b.createdAt) return -1;
              if(a.createdAt < b.createdAt) return 1;
              return 0;
          });
        }),
        tap(_ => this.log('fetched archive Fault')),
        catchError(this.handleError<ArchiveFault[]>('getArchiveFault', []))
      )
  }

  private handleError<T> (operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
 
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead
 
      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);
 
      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  private log(message: string) {
    console.log(`FaultService: ${message}`);
  }
}
